import { render, staticRenderFns } from "./shangjiDetail.vue?vue&type=template&id=225f2790&scoped=true&"
import script from "./shangjiDetail.vue?vue&type=script&lang=js&"
export * from "./shangjiDetail.vue?vue&type=script&lang=js&"
import style0 from "./shangjiDetail.vue?vue&type=style&index=0&id=225f2790&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "225f2790",
  null
  
)

export default component.exports